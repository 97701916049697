import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import TeaserBody from '../../components/TeaserBody'
import List from '../../components/List'
import ListItem from '../../components/ListItem'
import ContactSection from '../../components/ContactSection'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Enterprise Java"
           description="Profitieren Sie von unserem Know-how im Bereich Java-Enterprise-Technologien. Wir unterstützen Sie in der Wartung und Weiterentwicklung individueller Geschäftsanwendungen."
      />

      <BigTitle title="Enterprise Java" className="mask-black" imageRelativePath={'Titelbild_EnterpriseJava.jpg'}
                textInverted={true} menuInverted={true} logoInverted={true}>
        Viele Geschäftsanwendungen basieren auf Enterprise-Java-Technologien.<br/>
        Wir unterstützen Sie in der Wartung und Weiterentwicklung dieser Systeme.
      </BigTitle>

      <DefaultSection>
        <Article>
          <h1>Die Herausforderung</h1>
          <p>Die Java-Enterprise-Technologien definieren die Anwendungsarchitektur der letzten 20 Jahre. Wertvolle
            Geschäftsregeln, Schnittstellen zu Fremdsystemen und Benutzeroberflächen sind mit diesen Technologien
            implementiert. Diese Systeme sind oft essentiell für den Geschäftsbetrieb. Wir unterstützen Sie in der
            Pflege und Weiterentwicklung dieser Systeme.</p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1">
        <TeaserHeader title="Unser Angebot" hasBody={true}>
          <p>Maßgeschneiderte Anwendungssysteme verschaffen Wettbewerbsvorteile. Mit unserem Knowhow im Bereich der
            Java-Enterprise-Technologien helfen wir Ihnen, diesen Vorteil zu bewahren und maximieren.</p>
        </TeaserHeader>
        <TeaserBody>
          <List>
            <ListItem title="Wartung">
              <p className="text-black-50">Gemeinsam mit Ihnen arbeiten wir an der Weiterentwicklung bestehender
                Funktionalität und sorgen so für
                einen kontinuierlichen, stabilen Betrieb Ihrer Systemlandschaft.</p>
            </ListItem>
            <ListItem title="Migration">
              <p className="text-black-50">Modernisieren Sie Ihre Anwendungen Schritt für Schritt. Unsere Experten
                unterstützen Sie bei der
                Migration von obsoleten Technologien auf eine zukunftsfähige Alternative.</p>
            </ListItem>
            <ListItem title="Persistenz">
              <p className="text-black-50">Durch unser breites Knowhow zu Java-basierten Persistenztechnologien
                unterstützen wir Sie gern bei der
                Lösung von Problemen im Bereich Persistenz.</p>
            </ListItem>
          </List>
        </TeaserBody>
      </TeaserSection>

      <DefaultSection>
        <Article>
          <h1>Unsere Kompetenzen</h1>
          <p>
            Wir besitzen langjährige Erfahrung im Aufsetzen und Weiterentwickeln großer Geschäftsanwendungen auf Basis
            der Java-Enterprise-Technologien. Angefangen bei klassischen Drei-Schicht-Architekturen über Message-Broker
            bis hin zu hochverfügbaren, autonom agierenden Steuerungssystemen beherrschen wir die gesamte Bandbreite von
            Anwendungsarchitekturen, die mit diesen Technologien möglich sind. Application-Server-Cluster sind uns ebenso
            vertraut wie Grid-Caches, verteilte Transaktionen oder Messaging-Systeme. Neben Rich-Clients, über
            traditionelle Webtechnologien bis hin zu modernen, komponentenbasierten Webframeworks decken wir ebenso eine
            große Bandbreite von Oberflächentechnologien ab.
          </p>
          <p>
            Unsere Beratung ist technologiezentriert, herstellerneutral und branchenübergreifend. Unsere Kunden bewegen
            sich dabei im Spektrum von Industrie über Banken und Behörden bis in den Bereich E-Commerce.
          </p>
          <p>
            Von uns erbrachte Beratungsleistungen betreffen die Wartung und Weiterentwicklung im Bereich:
          </p>
          <ul>
            <li>Sachbearbeitungssysteme</li>
            <li>Fertigungssteuerungssysteme / Manufacturing Execution Systems</li>
            <li>Data Science Systems zur statistischen Fertigungs-/Prozesskontrolle</li>
            <li>Verteilte Systeme mit Middleware zur Systemintegration</li>
          </ul>
          <p>Aufgrund langjähriger Projekterfahrungen besitzen wir vertieftes Wissen über</p>
          <ul>
            <li>JPA mit EclipseLink</li>
            <li>JBoss Wildfly</li>
            <li>Graph-Datenbanken mit Neo4J</li>
            <li>Weboberflächen mit Angular</li>
            <li>RapidMiner</li>
            <li>Docker und Kubernetes</li>
          </ul>
        </Article>
      </DefaultSection>

      <ContactSection className="bg-level-1"/>

    </React.Fragment>
  )
}

export default Page
